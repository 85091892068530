import { ref, watch, computed } from '@vue/composition-api'
// Notification
import { debounce } from 'debounce'

export default function useWithdrawList() {
  // Use toast
  const refTransactionTable = ref(null)
  const startDateFilter = ref(null)
  const endDateFilter = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'User ID' },
    { key: 'username' },
    { key: 'fullName', label: 'Name' },
    { key: 'joined' },
    { key: 'totalDeposit', label: 'Total Deposits' },
    { key: 'noWD', label: 'No of W/D' },
    { key: 'totalWDProcessed', label: 'Total W/D Processed' },
    { key: 'amount', label: 'Requested amount' },
    { key: 'date', label: 'Requested Date' },
    { key: 'fulfillDate', label: 'Fulfilled date' },
    { key: 'bsb' },
    { key: 'acc' },
    { key: 'status' },
    { key: 'notes' },
    { key: 'action' },
  ]

  const tableColumnsPaid = [
    { key: 'id', label: 'User ID' },
    { key: 'username' },
    { key: 'fullName', label: 'Name' },
    { key: 'joined' },
    { key: 'totalDeposit', label: 'Total Deposits' },
    { key: 'noWD', label: 'No of W/D' },
    { key: 'totalWDProcessed', label: 'Total W/D Processed' },
    { key: 'amount', label: 'Requested amount' },
    { key: 'date', label: 'Requested Date' },
    { key: 'fulfillDate', label: 'Fulfilled date' },
    { key: 'bsb' },
    { key: 'acc' },
    { key: 'status' },
    { key: 'notes' },
  ]

  const statusOptions = ['All', 'Pending', 'Reject', 'Approve', 'Complete']

  const perPage = ref(100)
  const totalTransactions = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(null)
  const isSortDirDesc = ref(false)
  const sortBy = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refTransactionTable.value ? refTransactionTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTransactions.value,
    }
  })

  const refetchData = () => {
    refTransactionTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  watch([endDateFilter, startDateFilter], () => {
    if (endDateFilter && startDateFilter) { refetchData() }
  })

  watch(
    [searchQuery],
    debounce(() => {
      totalTransactions.value = 0
      refetchData()
    }, 700),
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveTransactionStatusVariant = status => {
    switch (status) {
      case 'pending':
        return 'primary'
      case 'reject':
        return 'danger'
      case 'approve':
        return 'info'
      case 'complete':
        return 'success'
      default:
        return ''
    }
  }

  return {
    tableColumns,
    tableColumnsPaid,
    perPage,
    currentPage,
    totalTransactions,
    dataMeta,
    perPageOptions,
    searchQuery,
    refTransactionTable,
    isSortDirDesc,
    sortBy,

    startDateFilter,
    endDateFilter,

    statusOptions,

    resolveTransactionStatusVariant,
  }
}
