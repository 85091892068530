<template>
  <b-modal
    id="modal-bank-info"
    title="Bank Info"
    hide-footer
    centered
  >
    <b-row class="mb-1 d-flex align-items-center">
      <b-col
        cols="4"
        class="font-weight-bold"
      >
        <span class="label__input">Account name:</span>
      </b-col>
      <b-col
        cols="8"
      >
        <b-form-input
          :value="accountName"
          type="text"
          readonly
        />
      </b-col>
    </b-row>
    <b-row class="mb-1 d-flex align-items-center">
      <b-col
        cols="4"
        class="font-weight-bold"
      >
        <span class="label__input">Account Number:</span>
      </b-col>
      <b-col
        cols="8"
      >
        <b-form-input
          :value="accountNumber"
          type="text"
          readonly
        />
      </b-col>
    </b-row>
    <b-row class="mb-1 d-flex align-items-center">
      <b-col
        cols="4"
        class="font-weight-bold"
      >
        <span class="label__input">BSB:</span>
      </b-col>
      <b-col
        cols="8"
      >
        <b-form-input
          :value="bsb"
          type="text"
          readonly
        />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,

} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
  },
  props: {
    accountName: {
      type: String,
      default: '---',
    },
    accountNumber: {
      type: String,
      default: '---',
    },
    bsb: {
      type: String,
      default: '---',
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
