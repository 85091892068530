<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="8" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <b-col cols="12" md="6" class="mb-md-0 d-flex align-items-center justify-content-center">
              From:
              <b-form-datepicker
                id="startDateFilter"
                v-model="startDateFilter"
                class="mx-2"
                locale="en-US"
                :date-format-options="{ month: '2-digit', day: '2-digit', year: 'numeric' }"
                placeholder="Start date"
              />
            </b-col>
            <b-col cols="12" md="6" class="mb-md-0 d-flex align-items-center justify-content-center">
              To:
              <b-form-datepicker
                id="endDateFilter"
                v-model="endDateFilter"
                class="mx-2"
                locale="en-US"
                :date-format-options="{ month: '2-digit', day: '2-digit', year: 'numeric' }"
                placeholder="End date"
              />
            </b-col>
          </b-col>
          <!-- Per Page -->
          <b-col cols="12" md="4" class="d-flex align-items-center justify-content-end">
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="d-flex align-items-center justify-content-end">
            <!-- <div class="d-flex align-items-center justify-content-end"> -->
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              class="btn-export"
              @click="handleExport"
            >
              <Spinner v-if="loadingExportFile" variant="success" />
              <span v-else>Export ABA File</span>
            </b-button>
            <!-- </div> -->
          </b-col>
        </b-row>
      </div>
      <div class="table-responsive">
        <b-table
          ref="refTransactionTable"
          :items="fetchWithdrawList"
          :fields="tableColumns"
          responsive
          show-empty
          primary-key="withdraw-table"
        >
          <template #empty>
            <div v-if="withdrawStore.loading" class="text-center my-2">
              <p>Loading... Please wait</p>
            </div>

            <div v-else-if="!withdrawStore.loading && fetchWithdrawList.length === 0" class="text-center my-2">
              No matching records found
            </div>
          </template>

          <!-- Column: UserId -->
          <template #cell(id)="{ item }">
            <div class="text-wrap column-userid">
              <span class="align-text-top text-capitalize">{{ item.userId }}</span>
            </div>
          </template>
          <!-- Column: UserName -->
          <template #cell(username)="{ item }">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{ item.username }}</span>
            </div>
          </template>
          <!-- Column: FirstName -->
          <template #cell(fullName)="{ item }">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{
                `${item.firstName} ${item.middleName} ${item.lastName}`
              }}</span>
            </div>
          </template>
          <!-- Column: joined -->
          <template #cell(joined)="{ item }">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize"
                >{{ formatTime(item.joinedTime) }} {{ formatDateLocal(item.joinedTime) }}</span
              >
            </div>
          </template>
          <!-- Column: totalDeposit -->
          <template #cell(totalDeposit)="{ item }">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{ item.depositAmount }}</span>
            </div>
          </template>
          <!-- Column: noWD -->
          <template #cell(noWD)="{ item }">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{ item.withdrawalCount }}</span>
            </div>
          </template>
          <!-- Column: totalWDProcessed -->
          <template #cell(totalWDProcessed)="{ item }">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{ item.withdrawalAmount }}</span>
            </div>
          </template>
          <!-- Column: Amount -->
          <template #cell(amount)="{ item }">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{ item.amount }}</span>
            </div>
          </template>
          <!-- Column: date -->
          <template #cell(date)="{ item }">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize"
                >{{ formatTime(item.createdTime) }} {{ formatDateLocal(item.createdTime) }}
              </span>
            </div>
          </template>
          <!-- Column: fulfillDate -->
          <template #cell(fulfillDate)="{ item }">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize"
                >{{ formatTime(item.completedTime) }} {{ formatDateLocal(item.completedTime) }}
              </span>
            </div>
          </template>

          <!-- Column: BSB -->
          <template #cell(bsb)="{ item }">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{ item.bsb }}</span>
            </div>
          </template>
          <!-- Column: acc -->
          <template #cell(acc)="{ item }">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{ item.number }}</span>
            </div>
          </template>

          <!-- Column: status -->
          <template #cell(status)="{ item }">
            <b-badge pill :variant="`light-${resolveTransactionStatusVariant(item.status)}`" class="text-capitalize">
              {{ item.status }}
            </b-badge>
          </template>
          <!-- Column: notes -->
          <template #cell(notes)="{ item }">
            <div v-if="item.notes" class="text-wrap text-note" @click="handleChangeNote(item)">
              <span class="align-text-top">{{ item.notes }}</span>
            </div>
            <div v-else style="cursor: pointer" @click="handleChangeNote(item)">
              <feather-icon icon="EditIcon" size="16" class="mr-0 mr-sm-50" />
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(action)="{ item }">
            <b-form-checkbox-group
              :key="`${item.withdrawalRequestId}`"
              v-model="selectedAction"
              name="event-filter"
              stacked
              @change="handleCheckboxChange(item)"
            >
              <b-form-checkbox
                v-for="(i, ind) in actionList"
                :key="i"
                name="event-filter"
                :value="`${ind}-${item.withdrawalRequestId}`"
                class="mb-1"
              >
                {{ i }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </template>
        </b-table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTransactions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <ModalBankInfo :account-name="bankInfo.accountName" :account-number="bankInfo.accountNumber" :bsb="bankInfo.bsb" />

    <b-modal id="modal-change-status" title="Change Status" centered @ok="handleSubmitModal" @hide="resetModal">
      <p class="text-black">Are you sure you want to {{ selectedStatus }} this one?</p>
      <template #modal-ok>
        <Spinner v-if="loadingUpdateStatus" />
        <span v-else>Submit</span>
      </template>
    </b-modal>
    <b-modal id="modal-change-note" title="Change Notes" centered @ok="handleSubmitModalNotes" @hide="resetModalNotes">
      <div>
        <b-form-textarea id="textarea" v-model="noteValue" rows="4" placeholder="Add notes..." />
      </div>
      <template #modal-ok>
        <span>Submit</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BPagination,
  VBModal,
  BBadge,
  BFormSelect,
  BFormCheckbox,
  BButton,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormDatepicker,
} from 'bootstrap-vue'
import { InfoIcon, EditIcon } from 'vue-feather-icons'
import vSelect from 'vue-select'
import { mapState, mapActions } from 'vuex'
import { formatDateShort, formatPrice, formatBetBottom, formatBetTop } from '@core/utils/filter'
import useToastHandler from '@/services/toastHandler'
import { formatTime, formatDateLocal, formatDate, convertTimeStart, convertTimeEnd } from '@/helpers/utils'
import Spinner from '@core/components/Spinner'
import Ripple from 'vue-ripple-directive'
import ABA from 'aba-generator'
import useWithdrawList from './useWithdrawList'
import ModalBankInfo from './ModalBankInfo.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    vSelect,
    BBadge,
    InfoIcon,
    EditIcon,
    ModalBankInfo,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      bankInfo: {
        accountName: '',
        accountNumber: '',
        bsb: '',
      },
      selectedStatus: null,
      formatTime,
      formatDateLocal,
      Spinner,
      withdrawId: null,
      loadingExportFile: null,
      actionList: ['UnApprove', 'Decline'],
      selectedAction: [],
      selectedItem: null,

      noteValue: '',
      idNote: '',
    }
  },
  computed: {
    ...mapState({
      withdrawStore: s => s.transaction.withdrawList,
    }),
    ...mapState('transaction/updateStatus', ['loadingUpdateStatus']),
  },

  watch: {
    selectedAction(val) {
      if (val.length === 1) {
        this.selectedStatus = this.formatValueAction(val[0])
        this.$bvModal.show('modal-change-status')
      }
    },
  },

  methods: {
    ...mapActions({
      getListWithdraw: 'transaction/withdrawList/getListWithdraw',
      updateWithdrawStatus: 'transaction/updateStatus/updateWithdrawStatus',
      updateWithdrawStatusList: 'transaction/updateStatus/updateWithdrawStatusList',
      updateWithdrawCompleteAll: 'transaction/updateStatus/updateWithdrawApproveAll',
      updateNote: 'transaction/updateStatus/updateNote',
    }),
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    async fetchWithdrawList() {
      const data = await this.getListWithdraw({
        limit: this.perPage,
        page: this.currentPage,
        status: 'approve',
        ...(this.startDateFilter && this.endDateFilter && { 'createdTime[startDate]':  this.convertTimeStart(this.startDateFilter) }),
        ...(this.endDateFilter && this.endDateFilter && { 'createdTime[endDate]': this.convertTimeEnd(this.endDateFilter) }),
      })
      if (!data && this.withdrawStore.error) return this.showToastError(false, 'Error fetching transaction list')
      this.totalTransactions = this.withdrawStore.totalPage
      return data
    },
    handleSetBankInfo(bankInfo) {
      this.bankInfo = bankInfo
    },
    resetModal() {
      this.selectedAction = []
      this.selectedStatus = null
      this.selectedItem = null
    },
    handleCheckboxChange(checkedItems) {
      this.selectedItem = checkedItems
    },
    formatValueAction(val) {
      if (!val) return ''
      const valNumber = val.slice(0, 1)
      return this.actionList[Number(valNumber)]
    },
    handleSelectWithdrawItem(id) {
      if (this.selectedStatus.includes(id)) {
        this.selectedStatus = this.selectedStatus.filter(item => item.id !== id)
      } else {
        this.selectedStatus.push(id)
      }
    },
    async handleSubmitModal() {
      const status = this.selectedStatus === 'Decline' ? 'Reject' : 'review'
      const res = await this.updateWithdrawStatus({
        id: this.selectedItem.withdrawalRequestId,
        status: status.toLocaleLowerCase(),
      })

      if (res) {
        this.refTransactionTable.refresh()
        this.resetModal()
      }
    },
    async downloadFile(abaContent) {
      const date = formatDate(new Date(), 'yyMMdd')
      const abaBlob = new Blob([abaContent], { type: 'text/plain' })
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(abaBlob)
      downloadLink.download = `WithdrawalSDP-${date}.aba`
      document.body.appendChild(downloadLink)
      downloadLink.click()

      document.body.removeChild(downloadLink)
      const res = await this.updateWithdrawCompleteAll({
        status: 'approve',
        newStatus: 'complete',
      })
      if (res) this.refTransactionTable.refresh()
    },
    async handleExport() {
      const date = formatDate(new Date(), 'yyMMdd')
      this.loadingExportFile = true

      const res = await this.getListWithdraw({
        status: 'approve',
        type: 'bank-account',
        withAllData: 1
      })

      if (!res.length) return
      const pays = res?.map(item => {
        return {
          bsb: item.bsb,
          transactionCode: "50",
          account: item.number.replace(/\s/g, ""),
          amount: item.amount ? +item.amount.toFixed(2) : 0,
          accountTitle: `${item.firstName} ${item.middleName} ${item.lastName}`,
          reference: 'UPC  Showdown',
          traceBsb: '084-472',
          traceAccount: '738301686',
          remitter: 'UPC  Showdown',
        }
      })

      const totalAmout = pays.reduce((acc, item) => acc + item.amount, 0)

      const aba = new ABA({
        header: {
          bank: 'NAB',
          user: `SDP   Trading`,
          userNumber: '000000',
          description: 'UPCSHOWDOWN',
        },
        footer: {
          netTotal: "0000000000",
          debitTotal: +totalAmout.toFixed(2),
        },
      })

      const file = aba.generate(pays)
      this.loadingExportFile = false

      this.downloadFile(file)
    },

    handleChangeNote(item) {
      this.noteValue = item.notes
      this.idNote = item.withdrawalRequestId
      this.$bvModal.show('modal-change-note')
    },
    async handleSubmitModalNotes() {
      const res = await this.updateNote({ note: this.noteValue, id: this.idNote })
      if (res) {
        this.showToastSuccess('Success', 'Change notes successfully!')
        this.refTransactionTable.refresh()
      }
    },
    resetModalNotes() {
      this.noteValue = ''
      this.idNote = ''
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()

    const {
      tableColumns,
      perPage,
      currentPage,
      totalTransactions,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      searchQuery,
      refTransactionTable,
      sortBy,

      statusOptions,

      startDateFilter,
      endDateFilter,

      resolveTransactionStatusName,
      resolveTransactionStatusVariant,
    } = useWithdrawList()

    return {
      // Sidebar
      tableColumns,
      perPage,
      currentPage,
      totalTransactions,
      dataMeta,
      perPageOptions,
      searchQuery,
      refTransactionTable,
      isSortDirDesc,
      sortBy,

      statusOptions,

      startDateFilter,
      endDateFilter,

      resolveTransactionStatusName,
      resolveTransactionStatusVariant,

      showToastError,
      showToastSuccess,

      formatDateShort,
      formatPrice,
      formatBetBottom,
      formatBetTop,
      formatDate,

      convertTimeStart,
      convertTimeEnd,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.margin-x-auto {
  margin-block: auto;
}
.box__info {
  max-width: 75px;
}

.label__input {
  color: #414753;
  opacity: 1 !important;
}

.box__edit {
  max-width: 55px;
  display: flex;
  justify-content: center;
}

.text-black {
  color: #000;
}

.btn-change-status {
  margin-left: auto;
  margin-top: auto;
  background: #2b65a5 !important;
}

.btn-change-status:hover {
  background: #2b65a5 !important;
}
.btn-change-status:focus {
  background: #2b65a5 !important;
}
.btn-export {
  min-width: 165px;
  margin-right: 4px;
  min-height: 37px;
  margin-top: 24px;
}
.column-userid {
  max-width: 200px;
  min-width: 100px;
  word-break: break-word;
}
</style>
