<template>
  <div>
    <LoadingPage v-if="loading" />

    <b-card v-else>
      <b-tabs
        v-model="tabIndex"
        pills
        lazy
      >
        <!-- Tab: Approvals -->
        <b-tab>
          <template #title>

            <BLink
              :to="{}"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 0 ? 'active-title' : ''"
              >Approvals</span>
            </BLink>
          </template>

          <WithdrawTabApprovals class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab:Ready for Payment -->
        <b-tab>
          <template #title>

            <BLink
              :to="{ query: { tab: 'ready-payment' } }"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 1 ? 'active-title' : ''"
              >Ready for Payment</span>
            </BLink>
          </template>

          <WithdrawTabReadyPayment class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab:Review -->
        <b-tab>
          <template #title>

            <BLink
              :to="{ query: { tab: 'review' } }"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 2 ? 'active-title' : ''"
              >To be reviewed</span>
            </BLink>
          </template>

          <WithdrawTabReview class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab:Paid -->
        <b-tab>
          <template #title>

            <BLink
              :to="{ query: { tab: 'paid' } }"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 3 ? 'active-title' : ''"
              >Paid</span>
            </BLink>
          </template>

          <WithdrawTabPaid class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab:Declined -->
        <b-tab>
          <template #title>

            <BLink
              :to="{ query: { tab: 'declined' } }"
              class="font-weight-bold d-block text-nowrap text-body"
            >
              <span
                class="d-none d-sm-inline"
                :class="tabIndex === 4 ? 'active-title' : ''"
              >Declined</span>
            </BLink>
          </template>

          <WithdrawTabDecline class="mt-2 pt-75" />
        </b-tab>

      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import LoadingPage from '@core/components/LoadingPage'
import {
  BCard, BLink, BTab, BTabs,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import WithdrawTabApprovals from './WithdrawTabApprovals'
import WithdrawTabReadyPayment from './WithdrawTabReadyPayment'
import WithdrawTabReview from './WithdrawTabReview'
import WithdrawTabPaid from './WithdrawTabPaid'
import WithdrawTabDecline from './WithdrawTabDecline'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    WithdrawTabApprovals,
    WithdrawTabReadyPayment,
    WithdrawTabReview,
    WithdrawTabPaid,
    WithdrawTabDecline,
    LoadingPage,
    BLink,
  },
  data() {
    return {
      loading: false,
      tabIndex: 0,
    }
  },
  computed: {
    ...mapState({
      userData: s => s.user.detail.userData,
      stateAddress: s => s.user.newUserParams.state,
    }),
  },

  mounted() {
    switch (this.$route.query.tab) {
      case 'ready-payment':
        this.tabIndex = 1
        break
      case 'rebuy-token-list':
        this.tabIndex = 2
        break
      case 'review':
        this.tabIndex = 3
        break
      case 'paid':
        this.tabIndex = 4
        break
      case 'declined':
        this.tabIndex = 5
        break
      default:
        break
    }
  },
  async created() {
    // this.loading = true
    // await this.syncDetail()
    // this.loading = false
  },
  methods: {
    ...mapActions({
      fetchUser: 'user/detail/asyncUser',
      getNewUserParamsAsync: 'user/newUserParams/getNewUserParamsAsync',
    }),
    // async syncDetail() {
    //   await this.getNewUserParamsAsync()
    //   await this.fetchUser({ id: this.$router.currentRoute.params.id })
    // },
  },
}
</script>

<style lang="scss" scoped>
.active-title {
  color: white;
}
</style>
